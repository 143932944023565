/* Import direct de la Webfont pour un rendu plus lisse
  @import url(https://fonts.googleapis.com/css?family=Lato); */
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v13/UyBMtLsHKBKXelqf4x7VRQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v13/1YwB1sO8YE1Lyjf12WNiUA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

/* Généric */
.invisible {
  visibility: hidden;
}

.nowrap {
  white-space: nowrap;
}

.margin-bottom {
  margin-bottom: 15px;
}

body {
  opacity: 1;
  -webkit-transition: opacity 0.5s linear;
  -o-transition: opacity 0.5s linear;
  transition: opacity 0.5s linear;
  filter: alpha(opacity=100);
}

body.design {
  background-attachment: fixed;
  background-size: cover;
  background-image: url('/css/images/visual/design.png');
}

body.design1 {
  background-image: url('/css/images/visual/design_1.png');
}

body.design2 {
  background-image: url('/css/images/visual/design_2.png');
}

body.design3 {
  background-image: url('/css/images/visual/design_3.png');
}

body.design section#footer .inner {
  background: rgba(255, 255, 255, 0.9);
}

.password_request label {
  display: none;
}

html.waitingMode body {
  opacity: 0.5;
  -webkit-transition: opacity 0.75s linear;
  -o-transition: opacity 0.75s linear;
  transition: opacity 0.75s linear;
  filter: alpha(opacity=50);
}

html.waitingMode > * {
  cursor: wait !important;
}

/* Bootstrap update */

.modal {
  z-index: 999999;
}

.modal#eamLoginModal {
  text-align: center;
  z-index: 1000000;
}

@media (min-width: 768px) {
  .modal#eamLoginModal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
}

.modal#eamLoginModal .modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.navbar-default .navbar-nav.navbar-right > li > a.dropdown-toggle {
  text-transform: lowercase;
  color: #555;
  background-color: #e7e7e7;
}

.navbar-default.cron-has-error .navbar-nav.navbar-right > li > a.dropdown-toggle span {
  color: #a94442;
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
  color: #fff !important;
}

.nav-tabs > li > a {
  color: #555555;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: #337ab7;
}

.nav > li.separator {
  text-align: center;
  color: #777;
}

.nav > li.separator.disabled > span {
  color: #777777;
}

.navbar-default .navbar-nav > li > a.lower {
  text-transform: lowercase;
}

.radio {
  display: inline-block;
  margin: 10px;
  white-space: nowrap;
}

#segmentDuplicateModal .radio {
  width: 45%;
}

#segmentManageLinkModal .modal-body {
  overflow-y: auto;
  max-height: 800px;
}

.alert {
  margin: 10px 0;
}

.btn.btn-grey {
  background-color: #777;
  border-color: #555;
}

.btn.btn-grey:hover {
  background-color: #555;
}

table td pre {
  max-height: 400px;
  overflow: auto;
  width: 1120px;
}

.tab-content > .tab-pane {
  padding-top: 8px;
}

.table-curved {
  border-collapse: separate;
  border: solid #ccc 1px;
  border-radius: 6px;
  border-left: 0;
  border-top: 0;
}

.table-curved > thead:first-child > tr:first-child > th {
  border-bottom: 0;
  border-top: solid #ccc 1px;
}

.table-curved td, .table-curved th {
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
}

.table-curved > :first-child > :first-child > :first-child {
  border-radius: 6px 0 0 0;
}

.table-curved > :first-child > :first-child > :last-child {
  border-radius: 0 6px 0 0;
}

.table-curved > :last-child > :last-child > :first-child {
  border-radius: 0 0 0 6px;
}

.table-curved > :last-child > :last-child > :last-child {
  border-radius: 0 0 6px 0;
}

.table > thead > tr > th {
  background-color: #eee;
}

.table > tbody > tr > td.noborder {
  border: none;
}

.form-inline .form-group {
  margin-right: 15px;
}

.form-inline .form-control.large {
  display: block !important;
  width: 100%;
}

.form-group > .form40 {
  width: 40%;
  min-width: 260px;
}

.bs-callout {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px;
}

.bs-callout h4 {
  margin-top: 0;
  margin-bottom: 5px;
}

.bs-callout p:last-child {
  margin-bottom: 0;
}

.bs-callout code {
  border-radius: 3px;
}

.bs-callout + .bs-callout {
  margin-top: -5px;
}

.bs-callout-default {
  border-left-color: #777;
}

.bs-callout-default h4 {
  color: #777;
}

.bs-callout-primary {
  border-left-color: #428bca;
}

.bs-callout-primary h4 {
  color: #428bca;
}

.bs-callout-success {
  border-left-color: #5cb85c;
}

.bs-callout-success h4 {
  color: #5cb85c;
}

.bs-callout-danger {
  border-left-color: #d9534f;
}

.bs-callout-danger h4 {
  color: #d9534f;
}

.bs-callout-warning {
  border-left-color: #f0ad4e;
  background-color: #fcfbe9;
}

/*
.bs-callout-warning h4 {
    color: #f0ad4e;
}
*/
.bs-callout-warning .text-warning {
  color: #f0ad4e;
}

.bs-callout-info {
  border-left-color: #5bc0de;
}

.bs-callout-info h4 {
  color: #5bc0de;
}

.form-group {
  overflow: hidden;
}

.well.overflow .form-group {
  overflow: visible;
}

.overflowVisible .form-group {
  overflow: visible !important;
}

.has-error .notError .checkbox {
  color: inherit;
}

h1 small, .h1 small, h2 small, .h2 small, h3 small, .h3 small, h1 .small, .h1 .small, h2 .small, .h2 .small, h3 .small, .h3 .small {
  font-size: 85%;
}

/* Dismiss */
.dismiss {
  padding: 5px;
}

/* Help */
span.help, span.help-big {
  cursor: pointer;
}

.form-group span.help, .form-group span.help-big {
  padding: 5px;
}

.form-horizontal .form-group span.help, .form-horizontal .form-group span.help-big {
  padding: 0;
  margin: 0 0 0 -10px;
}

.popover.help-big {
  width: 800px;
  max-width: none;
}

.popover .close {
  margin: -27px 15px 0
}

h1, h2, h3, legend {
  font-family: 'Lato', Inherit, sans-serif;
}

h2 {
  color: #2C3E50;
  font-size: 25px;
  border: none;
  border-bottom: 1px solid rgb(229, 229, 229);
}

h3 {
  color: #2C3E50;
  font-size: 20px;
}

h2 small, h3 small {
  color: #337ab7;
}

h2 .popover-content, h3 .popover-content {
  color: #333;
}

.well fieldset legend {
  font-size: 17px;
  margin-left: -10px;
}

p.form-control-static {
  margin-left: 13px;
}

/* Security */
#security {
  background: rgb(255, 255, 255) none repeat scroll 0 0;
  padding: 20px 0;
  width: 99%;
  margin: 15% auto 0 auto;
}

#security .security_inner {
  width: 95%;
  margin: 0 auto;
  max-width: 542px;
   footer {
     font-size: 0.8em;
   }
}

@media (min-width: 768px) {
  #security {
    width: 632px;
  }
}

#security .panel-body {
  text-align: center;
}

#security .panel-body input.form-control {
  margin: 0 auto;
  max-width: 290px;
}

#security section#header .container {
  width: 100%;
  padding: 0;
}

/* Header */
section#header {
  background: #FEFEFE url('/css/images/bgHeader.png') repeat-x left bottom;
  padding-top: 10px;
}

#front section#header {
  background: transparent none;
}

section#header header .inner,
section#footer footer .inner {
  width: 100%;
}

section#header header .inner {
  position: relative;
}

@media (min-width: 768px) {
  section#footer footer .inner {
    overflow: auto;
  }
}

section#footer li + li::before {
  content: ' - ';
  margin-right: 5px;
}

header .userGuide {
  text-align: right;
  white-space: unset;
}

header .userGuide .fa {
  padding-right: 5px;
}

header .userGuide a {
  white-space: nowrap;
}

#front header .logo {
  width: 100%;
  text-align: center;

}

header .logo img {
  width: 280px;
  float: left;
}

#front header .logo img {
  float: none;
}

header div.userData {
  float: right;
  text-align: right;
}

header div.userData img {
  margin: 3px;
}

header div.userData a.active {
  opacity: 0.5;
}

@media (min-width: 768px) {
  section#header {
    height: 80px;
  }

  #front section#header {
    height: auto;
  }

  header .logo img {
    width: auto !important;
  }

  header div.userData, header div.userGuide {
    position: absolute;
    right: 0;
  }

  header div.userGuide {
    top: 2em;
  }

  header div.userData {
    top: 3.5em;
  }

  #front header div.userData {
    position: static;
  }

  #front header .logo img {
    display: none;
  }

  #front header .logo {
    height: 110px;
    background: url(/css/images/logo.png) no-repeat center;
  }
}

/* Navigation */
#front section#navigation {
  display: none;
}

section#navigation {
  background-color: #2C3E50;
}

/* Menu */
section#navigation #menu {
  background-color: #2C3E50;
  border: 0 none;
}

section#navigation #menu.affix-top {
  position: static;
}

section#navigation #menu.affix {
  position: fixed;
  top: 0;
  width: 100%;
  border-radius: 0;
  z-index: 999999;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
}

section#navigation .affixHeightAbsorber {
  display: none;
}

section#navigation #menu.affix + .affixHeightAbsorber {
  display: block;
  height: 50px;
  background-color: #fff;
}

section#navigation #menu .nav_home a {
  padding: 12px 12px 0 0;
}

.navbar-default .navbar-nav > li > a {
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
  color: #ddd;
}

.navbar-toggle {
  background-color: #ddd;
}

.navbar-default .navbar-nav .open .dropdown-menu > li > a,
.navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
.navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
  color: inherit;
}

.navbar-default .navbar-nav .open .dropdown-menu > li > a.nav_disable {
  pointer-events: none;
  cursor: not-allowed;
  font-style: italic;
}

@media (max-width: 768px) {
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #fff;
  }

  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #ddd;
  }
}

/* Footer */
footer {
  margin: 40px 0 10px 0;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-top: 40px;
}

footer .inner, .footer .inner {
  color: #fff;
  background-color: #2C3E50;
  border: none;
  border-top: 1px solid #e7e7e7;
  padding: 10px 0;
  text-align: center;
  z-index: -1;
}

.footer .inner {
  text-align: right;
  padding-right: 5%;
}

footer a {
  color: #fff;
  text-decoration: underline;
}

footer a:hover {
  color: grey;
  text-decoration: none;
}

#front footer .inner {
  background-color: transparent;
  color: grey;
}

#front footer a {
  color: grey;
}

/* Navigation campagne */
nav#navCampaign ul {
  margin: 0;
  padding: 0;
}

nav#navCampaign ul li a {
  white-space: nowrap;
}


#pgn_show_more {
  display: inline;
}

/* Refresh message */
.refresh em {
  margin-left: 5px;
}

.input-group.eamDatePicker {
  width: 150px;
}

/* ListHelper */
#pgn_select_affichage {
  float: right;
}

#pgn_select_affichage label {
  margin-right: 10px;
  font-weight: inherit;
}

#pgn_form_result {
  clear: both;
  position: relative;
}

@media (min-width: 1200px) {
  #pgn_form_result th {
    white-space: nowrap
  }
}

#pgn_loading {
  z-index: 100;
  position: absolute;
  background: url('/css/images/ajax-loader.gif') no-repeat center center;
  width: 300px;
  height: 50px;
  top: 50px;
  left: 0;
}

#pgn_form_result #pgn_table_results {
  display: inline-table;
}

#pgn_empty_result {
  margin-top: 20px;
}

#pgn_table_results span[data-pgn-sort] {
  margin: 0 5px;
  cursor: pointer;
  color: #aaa;
}

#pgn_table_results span[data-pgn-sort]:hover,
#pgn_table_results span[data-pgn-sort].active {
  color: #333;
}

#pgn_table_results span[data-pgn-sort].active:hover {
  color: #000;
}

.btn-xs,
.btn-group-xs > .btn {
  margin: 0 2px;
  font-size: 10px;
}

/* Edit Access */
.form2Actions .btn.pull-left {
  margin-right: 5px;
}

/* Edit Account */
#form_edit_account fieldset legend {
  margin-top: 35px;
}

.list-inline > li {
  vertical-align: middle;
}

/* Tableaux responsive */
.tabContainer {
  overflow-x: auto;
}

/* Iframe Model */
.eamTemplateWide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 1000000;
}

.eamTemplate .eamTemplateAction {
  padding: 5px;
}

.eamTemplateWide .eamTemplateAction {
  background-color: transparent;
  position: fixed;
  right: 50px;
}

.eamTemplateWide .eamTemplateAction li a {
  visibility: hidden;
}

.eamTemplateIframeContainer {
  border: none;
}

.eamTemplateIframeContainer.waiting {
  background: url('/css/images/ajax-loader.gif') no-repeat 50% 2%;
}

.eamTemplateIframeContainer iframe {
  width: 100%;
  opacity: 1;
  border: none;
}

.eamTemplateIframeContainer.waiting iframe {
  transition: opacity 0.5s, visibility 0.5s;
  opacity: .2;
}

/* Iframe Click */
.eamIframe {
  text-align: center;
}

#eamIframeClick {
  border: none;
}

/* Statistic resume */
.eamStatisticResumeBlock {
  height: 140px;
  margin: 5px 0;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #3498db;
}

.eamStatisticResumeFirstPart {
  font-size: 45px;
  margin-left: 10px;
}

.eamStatisticResumeSecondPart {
  font-size: 25px;
  margin-bottom: 0;
}

.eamStatisticResumeImage {
  background-color: #c3e3cb;
  line-height: 140px;
  border-radius: 10px;
  height: 100%;
  text-align: center;
}

.eamStatisticResumeImage img {
  height: auto;
  max-height: 100%;
  width: auto;
  max-width: 100%
}

.eamStatisticResumeText {
  background-color: #3498db;
  border-radius: 10px;
  height: 100%;
  left: -15px;
  padding: 20px;
}

.eamStatisticResumeText[data-mode="resume"] {
  line-height: 140px;
  padding: 0 15px;
}

.eamStatisticResumeText ul {
  margin-bottom: 0;
  vertical-align: sub;
}

.eamStatisticResumeText p {
  color: white
}

.eamStatisticResumeText p.center-block {
  line-height: 100px;
}

.eamTableUnsubscribeRecipient {
  margin-left: 10%;
}

.eamStatisticResumeTextResume {
  margin: 0 10px;
}

div.tableResume {
  text-align: center;
  max-width: 360px;
  margin: 0 auto;
}

div.tableResume div.bordered {
  border-top: 1px solid #ccc;
  padding-top: 10px;
  margin-top: 10px;
}

.blocPercent {
  display: flex;
  margin-bottom: 5px;
}

.blocPercent .blocType {
  flex-grow: 1;
  background-color: #DDD;
  border-radius: 5px;
  font-size: 1.7em;
  font-weight: 600;
  margin-right: 5px;
  text-transform: uppercase;
}

.blocPercent .blocType > div {
  display: inline-block;
  margin: 0;
  width: 50%;
  text-align: center;
}

.blocPercent .blocType .desktop {
  color: #60A89C;
}

.blocPercent .blocType .mobile {
  color: #6FA9CE;
}

.blocPercent .blocDesc {
  width: 30%;
  background-color: #C3E3CB;
  border-radius: 5px;
  padding: 5px 10px;
}

.blocPercent .blocDesc .progress {
  margin-bottom: 1%;
}

.blocPercent .blocDesc .progress-bar {
  min-width: 2em;
}

.blocPercent .blocDesc > div.row {
  margin: 15px 0;
}

.blocPercent .blocDesc > div.row > div {
  padding: 0 1px;
}

.chartContainer {
  padding: 19px 0;
}

/* Planning de routage */
.tooltip_v2 {
  color: #000;
  background-color: #fff;
  border: 2px solid #000;
}

#pagination-schedule {
  width: 480px;
  margin: 0 auto;
  padding: 0;
}

#pagination-schedule ul {
  margin: 0;
  padding: 0;
}

#pagination-schedule ul li {
  display: inline-block;
  text-align: left;
  margin: 0 5px;
  padding: 0 0 0 15px;
}

#table-schedule .ul_creneau_liste {
  overflow: auto;
  width: 100%;
  height: 150px;
  padding: 0;
}

#table-schedule .ul_creneau_liste li {
  list-style-type: none;
  margin: 0;
  padding: 1%;
  font-size: .8em;
  float: left;
  width: 49%;
  text-align: left;
  line-height: 1em;
}

#table-schedule .ul_creneau_liste li a {
  color: #000;
}

#table-schedule {
  width: 100%;
}

#table-schedule th {
  width: 14%;
  text-align: center;
}

#table-schedule td {
  width: 14%;
  height: 150px;
  vertical-align: top;
  text-align: center;
  padding: 0;
}

#table-schedule td.out {
  background-color: #ddd;
}

#table-schedule td.now {
  background-color: #DEE8F1;
}

#table-schedule td strong em {
  background-color: #EEEEEE;
  display: block;
  font-size: 0.7em;
}

#eamRoutingTestContainer h6 {
  font-size: 1em;
  margin: 0 0 0;
  padding: 0;
}

#eamRoutingTestContainer .alert-warning h6 {
  margin-bottom: -1.5em;
}

/* CRON HISTORY */
.resume .bs-callout {
  font-weight: 700;
  padding: 10px;
  margin: 0;
  border-top: 0;
}

.resume ul {
  margin: 5px 0 -5px;
  padding: 0;
}

.resume li {
  list-style-type: none;
  display: inline-block;
  width: 15%;
  padding: 10px 0 10px 15px;
  border: 1px solid #eee;
  border-right: 0;
}

.resume li.first {
  border-left: 0;
}

#pgn_form_result.count td span {
  border: 0 none;
  border-radius: 0;
  display: block;
  margin: -8px;
  padding: 8px;
}

/* Recipient - Unsubscribe */
.eamWellDownloadStat {
  margin-bottom: 9px;
  padding: 10px 15px;
}

.eamWellDownloadStat a {
  color: inherit;
}

/* Home */
#eamNewsLinkFa {
  float: right;
  font-size: 0.75em;
}

/* Relaunch */
#eamRelaunch .list-inline input {
  margin-left: 5px;
  width: 80px;
}

/* Model */
label.eamFormModelBlockPredefinedParagraph {
  background: #fff none no-repeat 0 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

  padding-left: 25px;
  min-height: 100px;
  min-width: 300px;
}

label.eamFormModelBlockPredefinedParagraph:hover {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}

.form-control.textareaCopyPaste {
  cursor: crosshair;
}

/* Beefree templating */
#bee-plugin-container {
  margin-bottom: 5%;
  iframe {
    height: 100vh !important;
    border-bottom: 1px solid #ddd !important;
  }
}

/* Segment contenu */
.segmentContentChoice {
  text-align: center;
  min-height: 350px;
  -webkit-box-shadow: 0 .5rem 1rem 0 rgba(0, 0, 0, 0.1), .5rem 0 1rem 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 .5rem 1rem 0 rgba(0, 0, 0, 0.1), .5rem 0 1rem 0 rgba(0, 0, 0, 0.1);
  padding: 0 20px;
  margin: 2% 4% 0;
  width: 25%;
  border-radius: 2%;
  h3 {
    font-size: 25px;
    font-weight: bold;
  }
  img {
    margin-bottom: 25px;
    max-height: 90px;
  }
  &.col-md-6{
    width: 40%;
    margin: 2% 5%;
  }
  &.col-md-12{
    width: 40%;
    margin: 2% 30%;
  }
}
#templateTable {
  width: 100%;
  td{
    height: 300px;
    width: 25%;
    position: relative;
    div {
      background-color: rgba(255,255,255,.8);
      height: 100%;
      width: 100%;
      p {
        padding-top: 150px;
      }
      &.divImage {
        position: relative;
        &.noimage {
          padding-top: 50px;
          img {
            height: 180px;
          }
        }
      }
      &.description {
        position: absolute;
        z-index: 1000;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: all 0.3s;
      }
      &.divImage:hover .description {
        opacity: 1;
      }
    }
    img {
      height: 300px;
    }
    a.btn {
      position: absolute;
      bottom: 10%;
      transform: translate(-50%);
    }
  }
}
